$(document).ready(function () {
  var fullHeight = function () {
    $('.js-fullheight').css('height', $(window).height());
    $(window).resize(function () {
      $('.js-fullheight').css('height', $(window).height());
    });
  };
  fullHeight();

  $('#sidebarCollapse').on('click', function () {
    $('#sidebar').toggleClass('active');
    $('.navbar').toggleClass('nav-margin-left');
  });
  $('ul li a').click(function () {
    $('li a').removeClass('active');
    $(this).addClass('active');
  });
});
